import {gql} from '@apollo/client';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


let client = null;

export const initGqlClient = (apiKey) => {
    console.log('<<<<<uri>>>>>', GRAPHQL);
    const httpLink = createHttpLink({
        uri: GRAPHQL,
    });

    const authLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                "exulu-api-key": apiKey,
            }
        }
    });

    client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });
};

export const getFeatures = (
    page = 1,
    perPage = 5,
    searchString = null,
    bug = null,
    companyId = null,
    releaseId = null,
    lastSeen = null,
    language = null,
    profileId = null,
    proposed = false,
    accepted = false,
    developing = false,
    released = false,
    beforeSeen = null,
    tags = null,
    preview = null
) => {
    console.log("page", page);
    console.log("perPage", perPage);
    console.log("searchString", searchString);
    console.log("profileId", profileId);
    console.log("beforeSeen", beforeSeen);
    console.log("tags", tags);
    if (!companyId) {
        console.error("Missing company id");
        return;
    }
    let filter = {};
    if (searchString) {
        filter.nameKeySearch = searchString;
    }
    if (profileId === "") {
        profileId = null;
    }
    filter.AND = [];
    filter.AND.push({_operators: {archived: {ne: true}}});
    filter.AND.push({company: companyId});
    filter.AND.push({showInEmbed: true});
    if (!preview) {
        if (lastSeen) {
            filter.AND.push({_operators: {publishedDate: {gt: lastSeen}}});
        }
        if (beforeSeen) {
            filter.AND.push({_operators: {publishedDate: {lt: beforeSeen}}});
        }

        if (!proposed) {
            filter.AND.push({_operators: {proposed: {ne: true}}});
        }
        if (!accepted) {
            filter.AND.push({_operators: {accepted: {ne: true}}});
        }
        if (!developing) {
            filter.AND.push({_operators: {developing: {ne: true}}});
        }
        if (!released) {
            filter.AND.push({_operators: {released: {ne: true}}});
        }

        console.log("filter.AND", filter.AND);

        if (bug) {
            filter.bug = true;
        }
        if (bug === false) {
            filter.AND.push({_operators: {bug: {ne: true}}});
        }
        if (tags) {
            filter.tagMatch = tags;
        }
    } else {
        filter.AND.push({_id: preview});
    }

    console.log("lastSeen", lastSeen);
    return client
    .query({
        fetchPolicy: "network-only",
        query: gql`
            query GetFeatures(
                $page: Int!,
                $limit: Int!,
                $language: String!,
                $filter: FilterFindManyFeaturesInput,
                $profile: MongoID
            ) {
                featurePagination(
                    page: $page,
                    perPage: $limit,
                    language: $language,
                    sort: CREATEDAT_DESC,
                    filter: $filter
                ) {
                    pageInfo {
                        pageCount
                        itemCount
                        currentPage
                        hasPreviousPage
                        hasNextPage
                    }
                    items {
                        _id
                        name
                        profileFeatureView(profile: $profile) {
                            _id
                            createdAt
                        }
                        epic
                        cta
                        showInEmbed
                        enableFeedback
                        ctaText
                        bug
                        headerImage
                        publishedDate
                        proposed
                        developing
                        accepted
                        released
                        archived
                        teaserText
                        background
                        published
                        body
                        company {
                            _id
                        }
                    }
                }
            }
        `,
        variables: {
            filter: filter,
            limit: perPage,
            page: page,
            language: language,
            profile: profileId,
        }
    });
};

export const getCompany = (apiKey, language) => {
    if (!apiKey) {
        console.error("missing company apiKey");
        return;
    }
    return client
    .query({
        fetchPolicy: "network-only",
        query: gql`
            query GetCompanyByApiKey($apiKey: String!, $language: String) {
                companyOne(filter: {
                    apiKey: $apiKey
                }) {
                    _id
                    name
                    feedbackUrls
                    modalConfig(language: $language) {
                        _id
                        autoOpen
                        autoOpenEpic
                        showCounter
                        enableSubscribers
                        enableSuggestions
                        showRoadmap
                        showFeatures
                        showFixes
                        alwaysShow
                        trigger
                        type
                        bubblePosition
                        bannerPosition
                        buttonId
                        buttonText
                        bannerButtonColor
                        modalBackgroundColor
                        modalBorderColor
                        modalHeaderColor
                        feedbackTheme
                        modalBodyColor
                        feedbackButtonColor
                        feedbackBackgroundColor
                        ratingButtonActiveBackground
                        ratingButtonActiveText
                        ratingButtonInactiveBackground
                        ratingButtonInactiveText
                        ratingModalMaxWidth
                        feedbackBorderColor
                        feedbackHeaderColor
                        feedbackBodyColor
                        feedbackTextColor
                        bannerTextColor
                        bannerBackgroundColor
                        bannerContainer
                        i18n_whatIsNew
                        i18n_back
                        i18n_noNewFeatures
                        i18n_checkItOut
                        i18n_learnMore
                        i18n_searchResults
                        i18n_epic
                        i18n_seenFeatures
                        i18n_latestBugFixes
                        i18n_roadmap
                        i18n_bugfix
                        i18n_feature
                        i18n_searchPlaceholder
                        i18n_let_us_know_what_you_think
                        i18n_already_sent_feedback
                        i18n_missing_email
                        i18n_missing_score
                        i18n_thanks_for_feedback
                        i18n_what_can_we_do_to_improve
                        i18n_send_feedback
                        i18n_not_now
                        i18n_load_more
                    }
                }
            }
        `,
        variables: {
            apiKey: apiKey,
            language: language
        }
    });
};

export const createFeatureViews = (featureViews) => {
    return client
    .mutate({
        mutation: gql`
            mutation createFeatureViews($featureViews: [CreateManyFeatureViewsInput!]!) { 
        featureViewsCreateMany(records: $featureViews) {
            records {
                _id
            }
        }
    }
`,
        variables: {
            featureViews: featureViews
        }
    });
};

export const createFeedback = (feature, score, feedback = null, profile = null) => {
    return client
    .mutate({
        mutation: gql`
            mutation createFeedback($feature: MongoID!, $feedback: String, $source: String, $score: Float!, $profile: MongoID) { 
        feedbackCreateOne(record: {
            feature: $feature,
            profile: $profile,
            feedback: $feedback,
            score: $score,
            source: $source
        }) {
            record {
                _id
            }
        }
    }
`,
        variables: {
            feature: feature,
            feedback: feedback,
            profile: profile,
            score: score,
            source: 'Exulu Embed'
        }
    });
};

// retrieve profile
export const profileById = (id, company) => {
    if (!id) {
        console.error("missing profile id");
        return;
    }
    return client
    .query({
        fetchPolicy: "network-only",
        query: gql`
            query GetProfileById($profile: MongoID, $externalProfileId: String, $company: MongoID!) {
                profileOne(filter: {_id: $profile, externalProfileId: $externalProfileId, company: $company}) {
                    _id
                    externalProfileId
                    company {
                        _id
                    }
                    latestFeatureView {
                        _id
                        createdAt
                    }
                    firstName
                    feedback(page: 1, perPage: 50) {
                      count
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                        currentPage
                      }
                      items {
                        _id
                        feature {
                            _id
                            name
                        }
                      }
                    }
                    unseenFeatureCount
                    lastName
                    email
                    tags
                    language
                }
            }
        `,
        variables: {
            company: company,
            profile: id
        }
    });
};
// retrieve profile
export const profileByExternalId = (externalProfileId, company) => {
    if (!externalProfileId) {
        return;
    }
    /* todo ROADMAP: if user has provided more than 50 feedbacks they are not loaded*/
    return client
    .query({
        fetchPolicy: "network-only",
        query: gql`
            query GetProfileByExternalId($externalProfileId: String!, $company: MongoID!) {
                profileOne(filter: {externalProfileId: $externalProfileId, company: $company}) {
                    _id
                    externalProfileId
                    company {
                        _id
                    }
                    latestFeatureView {
                        _id
                        createdAt
                    }
                    feedback(page: 1, perPage: 50) {
                      count
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                        currentPage
                      }
                      items {
                        _id
                        feature {
                            _id
                            name
                        }
                      }
                    }
                    firstName
                    unseenFeatureCount
                    lastName
                    email
                    tags
                    language
                }
            }
        `,
        variables: {
            company: company,
            externalProfileId: externalProfileId
        }
    });
};

export const profileByEmail = (email, company) => {
    if (!email) {
        return;
    }
    return client
    .query({
        fetchPolicy: "network-only",
        query: gql`
            query GetProfileByExternalId($email: String!, $company: MongoID!) {
                profileOne(filter: {email: $email, company: $company}) {
                    _id
                    externalProfileId
                    company {
                        _id
                    }
                    latestFeatureView {
                        _id
                        createdAt
                    }
                    feedback(page: 1, perPage: 50) {
                      count
                      pageInfo {
                        hasNextPage
                        hasPreviousPage
                        currentPage
                      }
                      items {
                        _id
                        feature {
                            _id
                            name
                        }
                      }
                    }
                    firstName
                    unseenFeatureCount
                    lastName
                    email
                    tags
                    language
                }
            }
        `,
        variables: {
            company: company,
            email: email
        }
    });
};

export const createProfile = (company, profile, apiKey) => {
    return client
    .mutate({
        mutation: gql`
            mutation CreateProfile($createdBy: String, $externalProfileId: String, $firstName: String, $lastName: String, $language: String, $email: String, $tags: [String], $company: MongoID!) {
                profileCreateOne(record: {
                    firstName: $firstName,
                    language: $language,
                    createdBy: $createdBy,
                    externalProfileId: $externalProfileId,
                    company: $company,
                    lastName: $lastName,
                    email: $email,
                    tags: $tags
                }) {
                    record {
                        _id
                        createdBy
                        firstName
                        unseenFeatureCount
                        lastName
                        email
                        tags
                    }
                }
            }
        `,
        variables: {
            company: company,
            createdBy: "API",
            externalProfileId: profile ? profile.external_id : null,
            firstName: profile ? profile.first_name : null,
            lastName: profile ? profile.last_name : null,
            language: profile ? profile.language : null,
            email: profile ? profile.email : null,
            tags: profile ? profile.tags : null
        }
    });
};

export const createStepCompleted = (step, company, profileId) => {
    return client
    .mutate({
        mutation: gql`
            mutation createStepCompleted($profile: MongoID!, $step: MongoID!, $company: MongoID!) {
        stepCompletedCreateOne(record: {profile: $profile, step: $step, company: $company}) {
            record {
                _id
            }
        }
    }
`,
        variables: {
            company: company,
            profile: profileId,
            step: step
        }
    });
};
