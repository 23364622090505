<script>
    import { getCookie, createCookie} from "./cookies";
    import {createFeedback} from "./api";
    export let feedback = null;
    export let feature = null;
    let sendingFeedback = false;
    let sentFeedbackSuccessfully = false;
    export let company_value;
    export let modalCallback;
    export let profile;
    let errors = [];

    console.log("Initialized feedback area");
    console.log("feedback", feedback);

    const setFeedbackScore = (e) => {
        console.log("current score", feedback.score);
        console.log("new score", e);
        if (feedback.score === e) {
            feedback.score = e - 1;
        } else {
            feedback.score = e;
        }
    }

    export function updateCompanyValue(data) {
        console.log("updateCompanyValue", data);
        company_value = data;
    }
    export function hideFeatureFeedback() {
        let values = getCookie("exuluHideFeatureFeedback");
        if (values) {
            values = JSON.parse(values);
        }
        if (values) {
            values.push(feedback.feature);
        } else {
            values = [feedback.feature];
        }
        createCookie("exuluHideFeatureFeedback", JSON.stringify(values), 7); // todo allow admin to set how long its hidden by default
        sentFeedbackSuccessfully = false;
        feedback.score = null;
        feedback.message = null;
        if (modalCallback) {
            modalCallback();
        }
    }

    export function sendFeatureFeedback() {
        if (!feedback.score) {
            errors["feedback"] = 402;
            return;
        }
        errors["feedback"] = null;
        sendingFeedback = true;
        console.log("feedback", feedback);
        console.log("_profile.id", profile.id);
        createFeedback.call(
            this,
            feedback.feature,
            feedback.score,
            feedback.message,
            profile ? profile.id : null
        ).then((result) => {
            sendingFeedback = false;
            sentFeedbackSuccessfully = true;
            setTimeout(function () {
                sentFeedbackSuccessfully = false;
                feedback = null;
                if (modalCallback) {
                    modalCallback();
                }
            }, 5000);
            console.log("result", result);
        }).catch((err) => {
            sendingFeedback = false;
            sentFeedbackSuccessfully = false;
            errors["feedback"] = 500;
            console.error("err", err);
        });
    }

    if (!feedback && feature) {
        feedback = {
            feature: feature._id,
            score: 0,
            message: null
        }
    }

</script>

<div class="feedback-modal-content-exulu exulu-overflow-hidden exulu-rounded exulu-mx-auto"
     style="--modal-header-color: {company_value?.modalConfig.feedbackHeaderColor};
                   --modal-body-color: {company_value?.modalConfig.feedbackBodyColor};
                   --feedback-modal-background-color: {company_value?.modalConfig.feedbackBackgroundColor};
                   --exulu-rating-btn-active: {company_value?.modalConfig.ratingButtonActiveBackground};
                   --exulu-rating-btn-active-text: {company_value?.modalConfig.ratingButtonActiveText};
                   --exulu-rating-btn-border-color: {company_value?.modalConfig.feedbackBorderColor};
                   --exulu-rating-btn-inactive: {company_value?.modalConfig.ratingButtonInactiveBackground};
                   --exulu-rating-btn-inactive-text: {company_value?.modalConfig.ratingButtonInactiveText};
                   --exulu-rating-modal-width: {company_value?.modalConfig.ratingModalMaxWidth};
           ">
    <div style="--banner-button-background-color: {company_value?.modalConfig.feedbackButtonColor}; --banner-text-color: {company_value?.modalConfig.feedbackTextColor};"
         class="exulu-modal-body">
        <div class="exulu-card-body exulu-py-3 exulu-pb-0 exulu-px-3">
            {#if !sentFeedbackSuccessfully}
                <h1 class="exulu-text-center exulu-card-title">
                    {#if company_value?.modalConfig.i18n_let_us_know_what_you_think }
                        <span class="exulu-font-bold">{company_value.modalConfig.i18n_let_us_know_what_you_think}</span>{/if}
                    {#if !company_value?.modalConfig.i18n_let_us_know_what_you_think }
                        <span class="exulu-font-bold">Did you like this feature? Let us know.</span>{/if}
                </h1>

                        {#if company_value?.modalConfig.feedbackTheme && company_value?.modalConfig.feedbackTheme !== 'emojis'}
                            {#if company_value?.modalConfig.feedbackTheme === 'stars'}
                                <div class="exulu-row exulu-d-flex exulu-mt-3">
                                    <button on:click={() => {setFeedbackScore(1)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 exulu-py-3 {feedback.score > 0 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="{feedback.score > 0 ? 'inherit' : 'none'}" stroke="currentColor" stroke-width="2"
                                             stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-star">
                                            <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(2)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 1 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="{feedback.score > 1 ? 'inherit' : 'none'}" stroke="currentColor" stroke-width="2"
                                             stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-star">
                                            <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(3)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 2 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="{feedback.score > 2 ? 'inherit' : 'none'}" stroke="currentColor" stroke-width="2"
                                             stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-star">
                                            <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(4)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 3 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="{feedback.score > 3 ? 'inherit' : 'none'}" stroke="currentColor" stroke-width="2"
                                             stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-star">
                                            <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(5)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border {feedback.score > 4 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="{feedback.score > 4 ? 'inherit' : 'none'}" stroke="currentColor" stroke-width="2"
                                             stroke-linecap="round" stroke-linejoin="round"
                                             class="feather feather-star">
                                            <polygon
                                                    points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                    </button>
                                </div>
                                <div class="exulu-row exulu-d-flex exulu-mt-1 exulu-opacity-50">
                                    <div class="exulu-col exulu-px-0 exulu-d-flex">
                                        <span class="exulu-text-left exulu-row exulu-ml-0 exulu-mr-auto exulu-mt-2 exulu-small">Terrible</span>
                                    </div>
                                    <div class="exulu-col exulu-px-0 exulu-d-flex">
                                        <span class="exulu-text-right exulu-row exulu-mr-0 exulu-ml-auto exulu-mt-2 exulu-small">Great</span>
                                    </div>
                                </div>
                            {/if}
                            {#if company_value?.modalConfig.feedbackTheme === 'numbers'}
                                <div class="exulu-row exulu-d-flex exulu-mt-3">
                                    <button on:click={() => {setFeedbackScore(1)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 0 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <span class="exulu-rating-number exulu-p-2">1</span>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(2)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 1 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <span class="exulu-rating-number exulu-p-2">2</span>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(3)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 2 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <span class="exulu-rating-number exulu-p-2">3</span>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(4)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border exulu-mr-2 {feedback.score > 3 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <span class="exulu-rating-number exulu-p-2">4</span>
                                    </button>
                                    <button on:click={() => {setFeedbackScore(5)}}
                                            class="exulu-btn exulu-rating-btn exulu-col exulu-rating-btn-border {feedback.score > 4 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <span class="exulu-rating-number exulu-p-2">5</span>
                                    </button>
                                </div>
                                <div class="exulu-row exulu-d-flex exulu-mt-1 exulu-opacity-50">
                                    <div class="exulu-col exulu-px-0 exulu-d-flex">
                                        <span class="exulu-text-left exulu-row exulu-ml-0 exulu-mr-auto exulu-mt-2 exulu-small">Terrible</span>
                                    </div>
                                    <div class="exulu-col exulu-px-0 exulu-d-flex">
                                        <span class="exulu-text-right exulu-row exulu-mr-0 exulu-ml-auto exulu-mt-2 exulu-small">Great</span>
                                    </div>
                                </div>
                            {/if}
                            {#if company_value?.modalConfig.feedbackTheme === 'text'}
                                <div class="exulu-row exulu-d-flex exulu-mt-3">

                                    <div class="exulu-col-12">
                                        <input on:click={() => {setFeedbackScore(1)}}
                                               checked={feedback.score === 1}
                                               type="radio" id="1" name="rating" value="1">
                                        <label for="1">Horrible</label>
                                    </div>

                                    <div class="exulu-col-12">
                                        <input on:click={() => {setFeedbackScore(2)}}
                                               checked={feedback.score === 2}
                                               type="radio" id="2" name="rating" value="2">
                                        <label for="2">Not great</label>
                                    </div>

                                    <div class="exulu-col-12">
                                        <input on:click={() => {setFeedbackScore(3)}}
                                               checked={feedback.score === 3}
                                               type="radio" id="3" name="rating" value="3">
                                        <label for="3">Okay</label>
                                    </div>

                                    <div class="exulu-col-12">
                                        <input on:click={() => {setFeedbackScore(4)}}
                                               checked={feedback.score === 4}
                                               type="radio" id="4" name="rating" value="4">
                                        <label for="4">Good</label>
                                    </div>

                                    <div class="exulu-col-12">
                                        <input on:click={() => {setFeedbackScore(5)}}
                                               checked={feedback.score === 5}
                                               type="radio" id="5" name="rating" value="5">
                                        <label for="5">Amazing</label>
                                    </div>
                                </div>
                            {/if}
                        {:else} <!-- EMOJIS-->
                            <div class="exulu-row exulu-d-flex exulu-mt-3">
                                <div class="exulu-col exulu-flex-column exulu-d-flex exulu-mr-2">
                                    <button on:click={() => {setFeedbackScore(1)}}
                                            class="exulu-row exulu-btn exulu-rating-btn {feedback.score === 1 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <img class="exulu-rating-emoji" src="https://d3elukd1a0jseo.cloudfront.net/1687895302650_confounded.png" alt="background image" />
                                    </button>
                                    <span class="exulu-text-center exulu-row exulu-mx-auto exulu-mt-2 exulu-small">Terrible</span>
                                </div>
                                <div class="exulu-col exulu-flex-column exulu-d-flex exulu-mr-2">
                                    <button on:click={() => {setFeedbackScore(2)}}
                                            class="exulu-row exulu-btn exulu-rating-btn {feedback.score === 2 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <img class="exulu-rating-emoji" src="https://d3elukd1a0jseo.cloudfront.net/1687895230563_slightly-frowning-face.png" alt="background image" />
                                    </button>
                                    <span class="exulu-text-center exulu-row exulu-mx-auto exulu-mt-2 exulu-small">Bad</span>
                                </div>
                                <div class="exulu-col exulu-flex-column exulu-d-flex exulu-mr-2">
                                    <button on:click={() => {setFeedbackScore(3)}}
                                            class="exulu-row exulu-btn exulu-rating-btn {feedback.score === 3 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <img class="exulu-rating-emoji" src="https://d3elukd1a0jseo.cloudfront.net/1687895254024_neutral-face.png" alt="background image" />
                                    </button>
                                    <span class="exulu-text-center exulu-row exulu-mx-auto exulu-mt-2 exulu-small">Ok</span>
                                </div>
                                <div class="exulu-col exulu-flex-column exulu-d-flex exulu-mr-2">
                                    <button on:click={() => {setFeedbackScore(4)}}
                                            class="exulu-row exulu-btn exulu-rating-btn {feedback.score === 4 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <img class="exulu-rating-emoji" src="https://d3elukd1a0jseo.cloudfront.net/1687895192545_slightly-smiling-face.png" alt="background image" />
                                    </button>
                                    <span class="exulu-text-center exulu-row exulu-mx-auto exulu-mt-2 exulu-small">Good</span>
                                </div>
                                <div class="exulu-col exulu-flex-column exulu-d-flex">
                                    <button on:click={() => {setFeedbackScore(5)}}
                                            class="exulu-row exulu-btn exulu-rating-btn {feedback.score === 5 ? 'exulu-rating-btn-active' : 'exulu-rating-btn-inactive'}">
                                        <img class="exulu-rating-emoji" src="https://d3elukd1a0jseo.cloudfront.net/1687895279093_grin.png" alt="background image" />
                                    </button>
                                    <span class="exulu-text-center exulu-row exulu-mx-auto exulu-mt-2 exulu-small">Great</span>
                                </div>
                            </div>
                        {/if}
                <div style="--modal-body-color: {company_value?.modalConfig.feedbackBodyColor};"
                     class="exulu-row exulu-d-flex exulu-mt-3">
                    <div class="exulu-mx-auto exulu-w-100">
                        <div>
                            <label class="exulu-small" for="feedback">
                                {#if company_value?.modalConfig.i18n_what_can_we_do_to_improve }
                                    <span>{company_value.modalConfig.i18n_what_can_we_do_to_improve}</span>{/if}
                                {#if !company_value?.modalConfig.i18n_what_can_we_do_to_improve }
                                    <span>What could we do to improve (optional)?</span>{/if}
                            </label>
                            <textarea name="feedback"
                                      id="feedback"
                                      class="exulu-form-control exulu-p-2 exulu-mt-2"
                                      type="text"
                                      bind:value={feedback.message}
                                      rows="3"
                                      placeholder=""
                                      aria-label="feedback"
                                      aria-describedby="feedback"></textarea>
                        </div>
                    </div>
                </div>
                <div class="exulu-row exulu-d-flex exulu-mt-3">
                    <button on:click={hideFeatureFeedback}
                            class="exulu-btn exulu-btn-light exulu-mr-2">
                        {#if company_value?.modalConfig.i18n_not_now }
                            <span>{company_value.modalConfig.i18n_not_now}</span>{/if}
                        {#if !company_value?.modalConfig.i18n_not_now }
                            <span>Not now</span>{/if}
                    </button>
                    <button disabled="{sendingFeedback}" on:click={sendFeatureFeedback}
                            class="exulu-btn exulu-btn-primary">
                        {#if company_value?.modalConfig.i18n_send_feedback }
                            <span>{company_value.modalConfig.i18n_send_feedback}</span>{/if}
                        {#if !company_value?.modalConfig.i18n_send_feedback }
                            <span>Send feedback</span>{/if}
                        {#if sendingFeedback }
                            <div class="exulu-lds-ring exulu-ml-2 exulu-my-auto">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        {/if}
                    </button>
                </div>
            {/if}
            {#if sentFeedbackSuccessfully}
                <h1 class="exulu-text-center">
                    {#if company_value?.modalConfig.i18n_thanks_for_feedback }
                        <span>{company_value.modalConfig.i18n_thanks_for_feedback}</span>{/if}
                    {#if !company_value?.modalConfig.i18n_thanks_for_feedback }
                        <span>Thank you for your feedback!</span>{/if}
                </h1>
            {/if}
            {#if errors["feedback"] === 402}
                <div class="exulu-row exulu-d-flex">
                    <p class="exulu-text-warning">
                        {#if company_value?.modalConfig.i18n_missing_score }
                            <span>{company_value.modalConfig.i18n_missing_score}</span>{/if}
                        {#if !company_value?.modalConfig.i18n_missing_score }
                            <span>Missing score.</span>{/if}
                    </p>
                </div>
            {/if}
        </div>
    </div>
    <div class="exulu-modal-footer exulu-d-flex">
        <div style="color: {company_value?.modalConfig.modalBodyColor} !important;"
             class="exulu-col exulu-small">
            Powered by <a href="https://exulu.com">Exulu</a>.
        </div>
    </div>
</div>
<style src="./feedback.scss" lang="scss"></style>