import MainComponent from './App.svelte';
/* TUTORIAL FROM: https://github.com/lingtalfi/TheBar/blob/master/discussions/inject-svelte-in-existing-app.md*/

window.Exulu = {
    init : function (options) {
        console.log("Initializing Svelte App");
        if (!options.apiKey) {
            console.error("Missing apiKey.");
            return;
        }
        if (!options.ENV || (
            options.ENV !== "dev" &&
            options.ENV !== "test" &&
            options.ENV !== "prod"
        )) {
            window.EXULU_EMBED_ENV = "dev";
        } else {
            window.EXULU_EMBED_ENV = options.ENV;
        }

        switch (window.EXULU_EMBED_ENV) {
            case "dev":
                window.EXULU_EMBED_URL = "http://localhost:4000";
                break;
            case "test":
                window.EXULU_EMBED_URL = "http://localhost:4000";
                break;
            case "prod":
                window.EXULU_EMBED_URL = "http://production.exulu.com";
                break;
        }

        /* todo move these from window context to instance*/
        console.log("OPTIONS", options);
        console.log("options.target", options.target);
        const instance = new MainComponent({
            target: options.target,
            props: {
                _callback: options.callback,
                _config: options.config,
                _apiKey: options.apiKey,
                _profile: options.profile,
                _language: options.language
            }
        });
        return instance;
    }
};
