<script context="module">

    /*********************************************************************************************/
    /******** FUNCTIONS AVAILABLE TO EXTERNAL CLIENTS (SVELTE script context="module") **********/
    /****** IMPORTANT: -> these functions need to be added to the window object in main.js ******/
    /*********************************************************************************************/
</script>

<script>
    /**********************/
    /****** IMPORTS ******/
    /*********************/
    import { getCookie, createCookie} from "./cookies";
    import moment from 'moment'
    import {
        getFeatures,
        createFeedback,
        initGqlClient,
        createStepCompleted,
        profileById,
        profileByExternalId,
        profileByEmail,
        createProfile,
        getCompany,
        createFeatureViews
    } from './api';
    import FeedbackArea from './FeedbackArea.svelte';
    export function showFeatures(companyId, releaseId) {
        if (!company_value) {
            console.error("<<<< EXULU NOT INITIALIZED OR WRONG API KEY PROVIDED >>>>")
            return;
        }
        console.log("show features for release id: " + releaseId);
        loadFeatures(null, companyId, releaseId);
    }

    export function hideFeatures() {
        /*featuredFeature = null;*/
        unseen_features_value = null;
        seen_features_value = null;
        closeFeaturesModal();
    }

    let feedbackModalInjectedInstance = null;

    // Allows you to inject the modal into a specific container
    // rather than as an overlay of the entire screen
    export function injectFeedbackModal(featureId, containerId) {
        console.log("containerId", containerId);
        console.log("featureId", featureId);
        const target = document.querySelector(containerId);
        console.log("target", target);
        feedbackModalInjectedInstance = new FeedbackArea({
            target,
            props: {
                company_value: company_value,
                profile: _profile,
                feedback: {
                    feature: featureId,
                    score: null,
                    message: null
                }
            }
        })
    }

    const closeFeedbackModal = () => {
        console.log("Closing feedback modal");
        showFeedbackModal = false;
    }

    export function getProfile() {
        return _profile;
    }

    export function updateModalConfig(
        {
            modalHeaderColor,
            modalBackgroundColor,
            modalBorderColor,
            modalBodyColor,
            bannerBackgroundColor,
            bannerButtonColor,
            feedbackTheme,
            feedbackButtonColor,
            feedbackBackgroundColor,
            ratingButtonActiveBackground,
            ratingButtonActiveText,
            ratingButtonInactiveBackground,
            ratingButtonInactiveText,
            ratingModalMaxWidth,
            feedbackBorderColor,
            feedbackHeaderColor,
            feedbackBodyColor,
            feedbackTextColor,
            bannerTextColor,
            showRoadmap,
            showFixes
        }, reloadFeatures = false) {
        if (!company_value) {
            return;
        }
        const companyClone = {...company_value}
        const modalConfigClone = {...companyClone.modalConfig}
        modalConfigClone.modalHeaderColor = modalHeaderColor;
        modalConfigClone.feedbackTheme = feedbackTheme;
        modalConfigClone.modalBackgroundColor = modalBackgroundColor;
        modalConfigClone.modalBorderColor = modalBorderColor;
        modalConfigClone.modalBodyColor = modalBodyColor;
        modalConfigClone.bannerBackgroundColor = bannerBackgroundColor;
        modalConfigClone.bannerButtonColor = bannerButtonColor;
        modalConfigClone.feedbackButtonColor = feedbackButtonColor;
        modalConfigClone.feedbackBackgroundColor = feedbackBackgroundColor;
        modalConfigClone.ratingButtonActiveBackground = ratingButtonActiveBackground;
        modalConfigClone.ratingButtonActiveText = ratingButtonActiveText;
        modalConfigClone.ratingButtonInactiveBackground = ratingButtonInactiveBackground;
        modalConfigClone.ratingButtonInactiveText = ratingButtonInactiveText;
        modalConfigClone.ratingModalMaxWidth = ratingModalMaxWidth;
        modalConfigClone.feedbackBorderColor = feedbackBorderColor;
        modalConfigClone.feedbackHeaderColor = feedbackHeaderColor;
        modalConfigClone.feedbackBodyColor = feedbackBodyColor;
        modalConfigClone.feedbackTextColor = feedbackTextColor;
        modalConfigClone.bannerTextColor = bannerTextColor;
        modalConfigClone.showRoadmap = showRoadmap;
        modalConfigClone.showFixes = showFixes;
        companyClone.modalConfig = modalConfigClone;
        company_value = companyClone;
        if (feedbackModalInjectedInstance) {
            feedbackModalInjectedInstance.updateCompanyValue(companyClone);
        }
        console.log('calling load features');
        if (reloadFeatures) {
            loadFeatures.call(this);
        }
    }

    /************************/
    /****** INIT VARS ******/
    /***********************/
    let previousUrl = '';
    let company_value;
    let loadingProfile = true;
    let feedback = null;
    let showBanner = false;
    let showBubble = false;
    let showFeaturesModal = false;
    let showFeedbackModal = false;
    let loadingUnseenFeatures = false;
    let loadingSeenFeatures = false;
    let loadingFixes = false;
    let loadingRoadmap = false;
    let externalProfileId = false;
    let exuluProfileId = false;
    let previousPageUnseenFeatures = null;
    let previousPageSeenFeatures = null;
    let previousPageFixes = null;
    let previousPageRoadmap = null;

    let pageUnseenFeatures = 1;
    let pageSeenFeatures = 1;
    let pageFixes = 1;
    let pageRoadmap = 1;

    let loadingMoreRoadmap = false;
    let loadingMoreFixes = false;
    let loadingMoreUnseenFeatures = false;
    let loadingMoreSeenFeatures = false;
    let loadingFeaturesModal = false;
    let featureSearchString = null;
    let selectedFeature = null;
    /*let featuredFeature = null;*/

    // Set using props on main.js
    export let _apiKey = null;
    export let _position = null;
    export let _callback = null;
    let internalCallback = [];
    let _companyId = null; // retrieved via API key
    export let _lastSeen = null;
    export let _profile = null;
    export let _config = null;
    export let _language = null;
    let modalStyling = {
        bannerBackgroundColor: "#d3deef",
        bannerButtonColor: "#0061f2"
    };
    let roadmap_value = [];
    let unseen_features_value = [];
    let unseen_features_has_next_page = false;
    let seen_features_value = [];
    let seen_features_value_has_next_page = false
    let fixes_value = [];
    let fixes_value_has_next_page = false;
    let roadmap_values = [];
    let roadmap_values_has_next_page = false;
    let maxHeight = '300px';


    console.log("EXULU >>>>>");
    /*(function(history) {
        var pushState = history.pushState;
        history.pushState = function(state) {
            if (typeof history.onpushstate == "function")
            {
                history.onpushstate({
                    state: state
                });
            }
            return pushState.apply(history, arguments);
        }
    })(window.history);*/

    let urlList = null;

    function checkForFeedbackModal(url) {
        console.log("checkForFeedbackModal");
        /* urlList:
            [
                {
                    _id: "sxs", <- feature id
                    feedbackUrls: [
                        "url1",
                        "url2"
                    ]
                }
            ]
            * */
        console.log("new url", url);
        let matchedOnce = false;

        console.log("urlList", urlList);
        if (!urlList || urlList.length < 1) {
            return;
        }
        urlList.forEach(x => {
            if (!x.feedbackUrls || x.feedbackUrls.length < 1) {
                return
            }
            x.feedbackUrls.forEach(y => {
                if (url.includes(y) && !matchedOnce) {
                    console.log("It's a match");
                    matchedOnce = true;
                    // TODO make sure a specific profile is only asked for feedback once!
                    showFeatureFeedback(x._id);
                }
            });

        });
    }

    export function showFeatureFeedback(featureId, containerId) {
        const _callback_show_feature_feedback_modal = function (fId) {
            let alreadyProvidedFeedbackForFeatures = null;
            console.log("_profile", _profile);
            let exuluHideFeatureFeedback = getCookie("exuluHideFeatureFeedback");
            if (exuluHideFeatureFeedback) {
                exuluHideFeatureFeedback = JSON.parse(exuluHideFeatureFeedback);
            }
            console.log("!! exuluHideFeatureFeedback !!", exuluHideFeatureFeedback);
            if (
                _profile.feedback &&
                _profile.feedback.items &&
                _profile.feedback.items.length > 0
            ) {
                alreadyProvidedFeedbackForFeatures = _profile.feedback.items.map(x => x.feature?._id);
            }

            console.log("alreadyProvidedFeedbackForFeatures", alreadyProvidedFeedbackForFeatures);
            console.log("exuluHideFeatureFeedback.includes(x._id) !!", exuluHideFeatureFeedback.includes(fId));
            if (
                (
                    alreadyProvidedFeedbackForFeatures &&
                    alreadyProvidedFeedbackForFeatures.length > 0 &&
                    alreadyProvidedFeedbackForFeatures.includes(fId)
                )
                ||
                (
                    exuluHideFeatureFeedback &&
                    exuluHideFeatureFeedback.length > 0 &&
                    exuluHideFeatureFeedback.includes(fId)
                )
            ) {
                console.log("user already provided feedback for feature or has hidden it in the recent past " + fId + " so we are not opening the modal");
                return;
            }

            feedback = {
                feature: fId,
                score: null,
                message: null
            };
            console.log("Opening feature feedback modal for feature: " + fId);
            closeFeaturesModal();

            // Means the user wants to show the rating area inside
            // another div
            if (containerId) {
                injectFeedbackModal(fId, containerId);
            } else {

                // Adds an event listener with a callback to close
                // the modal if user clicks outside the exulu-modal-wrapper
                function Callback(e) {
                    if (showFeedbackModal && e.target && e.target.className && e.target.className.includes("exulu-modal-wrapper")) {
                        closeFeedbackModal();
                        document.removeEventListener('click', Callback, false);
                    }
                }

                console.log("SHOWING FEEDBACK MODAL!");
                showFeedbackModal = true;
                document.addEventListener('click', Callback, false);
            }

        }

        if (!company_value) {
            if (!_apiKey) {
                console.error("<<<< EXULU NOT INITIALIZED OR WRONG API KEY PROVIDED >>>>")
                return;
            } else {
                // Used to track calls that were made before the
                // initialization scripts have finished running
                // This array of callbacks is then called after those scripts are finished
                internalCallback.push(_callback_show_feature_feedback_modal.bind(this, featureId));
                return;
            }
        }
        _callback_show_feature_feedback_modal(featureId);
    }

    export function hideFeatureFeedback() {
        let values = getCookie("exuluHideFeatureFeedback");
        if (values) {
            values = JSON.parse(values);
        }
        if (values) {
            values.push(feedback.feature);
        } else {
            values = [feedback.feature];
        }
        createCookie("exuluHideFeatureFeedback", JSON.stringify(values), 7); // todo allow admin to set how long its hidden by default
        closeFeedbackModal();
    }

    export function refreshSettings() {
        getCompany.call(this, _apiKey, _language).then((result) => {
            console.log("getCompany", result);
            company_value = result.data.companyOne;
            if (!company_value) {
                console.error("<<<< EXULU INVALID API KEY >>>>", _apiKey);
                return;
            }
            if (!company_value.modalConfig) {
                let copy = JSON.parse(JSON.stringify(company_value)); // need to copy as it is read only
                company_value = copy;
                company_value.modalConfig = {};
            }
            _companyId = result.data.companyOne._id;
        });
    }

    initGqlClient.call(this, _apiKey);

    /*******************************/
    /******** DATA LOADERS *********/
    /*******************************/
    const loadFeatures = (
        lastSeen = null,
        companyId = null,
        releaseId = null,
        loadUnseenFeatures = true,
        loadSeenFeatures = true,
        loadRoadmap = null,
        loadFixes = null
    ) => {
        if (!showFeaturesModal) {
            loadingFeaturesModal = true;
            openFeaturesModal();
        }
        console.log("companyId", companyId);
        console.log("_profile.id", _profile.id);
        console.log("releaseId", releaseId);
        if (!lastSeen && _lastSeen) {
            lastSeen = _lastSeen;
        }
        console.log("lastSeen", lastSeen);
        console.log("loadUnseenFeatures", loadUnseenFeatures);
        console.log("pageUnseenFeatures", pageUnseenFeatures);
        console.log("previousPageUnseenFeatures", previousPageUnseenFeatures);
        console.log("previousPageSeenFeatures", previousPageSeenFeatures);
        console.log("pageSeenFeatures", pageSeenFeatures);
        if (
            featureSearchString || loadUnseenFeatures
        ) {
            if (featureSearchString) {
                pageUnseenFeatures = 1;
            }
            if (pageUnseenFeatures === 1) {
                loadingUnseenFeatures = true;
            } else {
                loadingMoreUnseenFeatures = true;
            }
            previousPageUnseenFeatures = pageUnseenFeatures;
            getFeatures.call(
                this,
                pageUnseenFeatures,
                5,
                featureSearchString,
                featureSearchString ? null : false,
                companyId ? companyId : _companyId,
                releaseId,
                featureSearchString ? null : lastSeen,
                _language,
                _profile.id,
                featureSearchString ? true : false, // yeye I know <;)
                featureSearchString ? true : false,
                featureSearchString ? true : false,
                true,
                null,
                _profile.tags,
                _config && _config.modal && _config.modal.preview ? _config.modal.preview : null
            ).then((result) => {
                console.log("result", result);
                unseen_features_has_next_page = result.data.featurePagination.pageInfo.hasNextPage;
                if (!featureSearchString && _profile.id) {
                    const featureViews = result.data.featurePagination.items.map((x) => {
                        return {
                            company: companyId ? companyId : _companyId,
                            profile: _profile.id,
                            feature: x._id
                        }
                    });
                    if (featureViews && featureViews.length > 0) {
                        console.log("featureViews", featureViews);
                        createFeatureViews.call(this, featureViews);
                    }
                }
                if (featureSearchString || pageUnseenFeatures < 2) {
                    unseen_features_value = result.data.featurePagination.items;
                } else if (
                    (
                        !result.data.featurePagination.items ||
                        result.data.featurePagination.items.length < 1
                    ) && pageUnseenFeatures > 1
                ) {
                    pageUnseenFeatures = pageUnseenFeatures - 1;
                } else {
                    unseen_features_value = unseen_features_value.concat(result.data.featurePagination.items);
                }

                loadingUnseenFeatures = false;
                loadingMoreUnseenFeatures = false;
                loadingFeaturesModal = false;
                /*if (
                    result.data.featurePagination.items &&
                    result.data.featurePagination.items.length > 1 &&
                    pageUnseenFeatures === 1 &&
                    !featureSearchString
                ) {
                    featuredFeature = result.data.featurePagination.items[0];
                    unseen_features_value.shift();
                }*/
                console.log("unseen_features_value", unseen_features_value);
            });
        }

        if (
            lastSeen &&
            loadSeenFeatures &&
            previousPageSeenFeatures !== pageSeenFeatures
        ) { // only get "already seen" features if lastseen var is set
            if (pageSeenFeatures === 1) {
                loadingSeenFeatures = true;
            } else {
                loadingMoreSeenFeatures = true;
            }
            previousPageSeenFeatures = pageSeenFeatures;
            getFeatures.call(
                this,
                pageSeenFeatures,
                3,
                null,
                null,
                companyId ? companyId : _companyId,
                releaseId,
                null,
                _language,
                _profile.id,
                false,
                false,
                false,
                true,
                lastSeen,
                _profile.tags,
                _config && _config.modal && _config.modal.preview ? _config.modal.preview : null
            ).then((result) => {
                console.log("result", result);
                seen_features_value_has_next_page = result.data.featurePagination.pageInfo.hasNextPage;
                if (
                    (!result.data.featurePagination.items ||
                        result.data.featurePagination.items.length < 1) &&
                    pageSeenFeatures > 1
                ) {
                    pageSeenFeatures = pageSeenFeatures - 1;
                } else {
                    seen_features_value = seen_features_value.concat(result.data.featurePagination.items);
                }
                loadingSeenFeatures = false;
                loadingMoreSeenFeatures = false;
                /*if (!showFeaturesModal) {
                    openFeaturesModal();
                }*/
                console.log("seen_features_value", seen_features_value);
            });
        }

        // Do not add last seen for fixes, for fixes we always show the latest ones
        if (
            (company_value.modalConfig.showFixes ||
                loadFixes === true ) &&
            previousPageFixes !== pageFixes
        ) {
            if (pageFixes === 1) {
                loadingFixes = true;
            } else {
                loadingMoreFixes = true;
            }
            previousPageFixes = pageFixes;
            getFeatures.call(
                this,
                pageFixes,
                5,
                null,
                true,
                companyId ? companyId : _companyId,
                releaseId,
                null,
                _language,
                _profile.id,
                false,
                false,
                false,
                true,
                null,
                _profile.tags,
                _config && _config.modal && _config.modal.preview ? _config.modal.preview : null
            ).then((result) => {
                console.log("result", result);
                fixes_value_has_next_page = result.data.featurePagination.pageInfo.hasNextPage;
                if (
                    (!result.data.featurePagination.items ||
                        result.data.featurePagination.items.length < 1) &&
                    pageFixes > 1
                ) {
                    pageFixes = pageFixes - 1;
                } else {
                    fixes_value = fixes_value.concat(result.data.featurePagination.items);
                }
                console.log("fixes_value", fixes_value);
                loadingFixes = false;
                loadingMoreFixes = false;
                /*if (!showFeaturesModal) {
                    openFeaturesModal();
                }*/
            });
        }

        console.log("company_value.modalConfig.showRoadmap", company_value.modalConfig.showRoadmap);
        console.log("loadRoadmap", loadRoadmap);
        if (
            (company_value.modalConfig.showRoadmap ||
            loadRoadmap === true ) &&
            previousPageRoadmap !== pageRoadmap
        ) {
            if (pageRoadmap === 1) {
                loadingRoadmap = true;
            } else {
                loadingMoreRoadmap = true;
            }
            previousPageRoadmap = pageRoadmap;
            getFeatures.call(
                this,
                pageRoadmap,
                3,
                null,
                null,
                companyId ? companyId : _companyId,
                releaseId,
                null,
                _language,
                _profile.id,
                true,
                true,
                true,
                false,
                null,
                _profile.tags,
                _config && _config.modal && _config.modal.preview ? _config.modal.preview : null
            ).then((result) => {
                console.log("result", result);
                roadmap_values_has_next_page = result.data.featurePagination.pageInfo.hasNextPage;
                if (
                    (!result.data.featurePagination.items ||
                        result.data.featurePagination.items.length < 1) &&
                    pageRoadmap > 1
                ) {
                    pageRoadmap = pageRoadmap - 1;
                } else {
                    roadmap_values = roadmap_values.concat(result.data.featurePagination.items);
                }
                console.log("roadmap_values", roadmap_values);
                loadingRoadmap = false;
                loadingMoreRoadmap = false;
                /*if (!showFeaturesModal) {
                    openFeaturesModal();
                }*/
            });
        }
    };

    /*******************************/
    /****** USER INTERACTIONS ******/
    /*******************************/
    const deselectFeature = () => {
        selectedFeature = null;
        unseen_features_value = [];
        featureSearchString = null;
        loadFeatures.call(this);
    };

    const expandFeature = () => {
        maxHeight = 'none';
    };

    const collapseFeature = () => {
        maxHeight = '300px';
    };

    const selectFeature = (feature) => {
        console.log("<<<<<<<<<<<<<<<<<HI>>>>>>>>>>>>>>>>>", feature);
        console.log("selectFeature", feature);
        featureSearchString = null;
        selectedFeature = feature;
        if (!showFeaturesModal) {
            showFeaturesModal = true;
        }
        if (_profile.id) {
            createFeatureViews.call(this, [{
                company: _companyId,
                profile: _profile.id,
                feature: feature._id
            }]);
        }
    };

    /********************************/
    /****** SET / READ COOKIES ******/
    /********************************/


    /**********************************/
    /****** CLOSE / OPEN MODALS ******/
    /**********************************/

    const openFeaturesModal = (e) => {
        function Callback(e) {
            console.log("e.target.className", e.target.className);
            console.log("showFeaturesModal", showFeaturesModal);
            console.log("e.target.className.includes(\"exulu-modal-wrapper\")", e.target.className.includes("exulu-modal-wrapper"));
            if (showFeaturesModal && e.target.className.includes("exulu-modal-wrapper")) {
                closeFeaturesModal();
                document.removeEventListener('click', Callback, false);
            }
        }

        /*loadingFeaturesModal = false;*/
        showFeaturesModal = true;
        document.addEventListener('click', Callback, false);
    };

    const closeFeaturesModal = () => {
        // reset all values
        showFeaturesModal = false;
        previousPageUnseenFeatures = null;
        pageUnseenFeatures = 1;
        previousPageSeenFeatures = null;
        pageSeenFeatures = 1;
        /*previousPageFixes = null;*/
        /*pageFixes = 1;*/
        previousPageRoadmap = null;
        pageRoadmap = 1;
        roadmap_value = [];
        unseen_features_value = [];
        seen_features_value = [];
        /*fixes_value = [];*/
        roadmap_values = [];
    };

    const _profileByExternalId = () => {
        profileByExternalId.call(this, _profile.external_id, _companyId).then((result) => {
            console.debug("profileByExternalId", result);
            if (
                result.data &&
                !result.data.profileOne
            ) {
                if (_profile.email) {
                    _profileByEmail.call();
                    return;

                } else {
                    _createProfile.call(this);
                    return;
                }
            }

            _profile.id = result.data.profileOne._id;
            createCookie("exuluProfileId", _profile.id, 30);
            _lastSeen = result.data.profileOne.latestFeatureView ? result.data.profileOne.latestFeatureView.createdAt : null;
            _profile.unseenFeatureCount = result.data.profileOne.unseenFeatureCount;
            _profile.feedback = result.data.profileOne.feedback;
            console.log("_config", _config);
            if (
                (_config && _config.modal && _config.modal.showCta === true) ||
                (_profile.unseenFeatureCount > 0 && (!_config || !_config.modal || (_config && _config.modal && _config.modal.showCta !== false))) ||
                (company_value && company_value.modalConfig && company_value.modalConfig.alwaysShow)
            ) {
                showBanner = true;
                showBubble = true;
            }
            if (_callback) {
                _callback(_profile);
            }
            if (internalCallback && internalCallback.length > 0) {
                internalCallback.forEach(x => {
                    x.call();
                });
            }
            console.log("_config", _config);
            if (
                (
                    (company_value && company_value.modalConfig.autoOpen && _profile.unseenFeatureCount > 0) ||
                    (_config && _config.modal && _config.modal.autoOpen === true)
                ) &&
                (!_config || !_config.modal || _config.modal.autoOpen !== false)
            ) {
                console.log("Loading features");
                loadFeatures.call(this);
            }
            // Check here again, because sometimes the mutation observer is triggered before
            // the profile is loaded, in which case we do not yet executed check checkForFeedbackModal
            // but wait for it until we loaded the profile so we can be sure that the profile did not
            // already previously provide feedback
            loadingProfile = false;
            if (location.href !== previousUrl) {
                checkForFeedbackModal(location.href);
            }
            /* TODO refactor above code, its duplicated three times for each _profile function*/
        });
    };

    const _profileByEmail = () => {
        profileByEmail.call(this, _profile.email, _companyId).then((result) => {
            console.debug("profileByEmail", result);
            if (
                result.data &&
                !result.data.profileOne
            ) {
                _createProfile.call(this);
                1
                return;
            }

            _profile.id = result.data.profileOne._id;
            createCookie("exuluProfileId", _profile.id, 30);
            _lastSeen = result.data.profileOne.latestFeatureView ? result.data.profileOne.latestFeatureView.createdAt : null;
            _profile.unseenFeatureCount = result.data.profileOne.unseenFeatureCount;
            _profile.feedback = result.data.profileOne.feedback;
            if (
                (_config && _config.modal && _config.modal.showCta === true) ||
                (_profile.unseenFeatureCount > 0 && (!_config || !_config.modal || (_config && _config.modal && _config.modal.showCta !== false))) ||
                (company_value && company_value.modalConfig && company_value.modalConfig.alwaysShow)
            ) {
                showBanner = true;
                showBubble = true;
            }
            if (_callback) {
                _callback(_profile);
            }
            if (internalCallback && internalCallback.length > 0) {
                internalCallback.forEach(x => {
                    x.call();
                });
            }
            console.log("_config", _config);
            if (
                (
                    (company_value && company_value.modalConfig.autoOpen && _profile.unseenFeatureCount > 0) ||
                    (_config && _config.modal && _config.modal.autoOpen === true)
                ) &&
                (!_config || !_config.modal || _config.modal.autoOpen !== false)
            ) {
                console.log("Loading features");
                loadFeatures.call(this);
            }
            // Check here again, because sometimes the mutation observer is triggered before
            // the profile is loaded, in which case we do not yet executed check checkForFeedbackModal
            // but wait for it until we loaded the profile so we can be sure that the profile did not
            // already previously provide feedback
            loadingProfile = false;
            if (location.href !== previousUrl) {
                checkForFeedbackModal(location.href);
            }
        });
    };

    const _profileById = () => {
        profileById.call(this, _profile.id, _companyId).then((result) => {
            if (result.data && !result.data.profileOne) {
                // could happen if for example the profile was deleted but the id was still stored in a cookie
                console.error("no profile found for id", _profile.id);
                _createProfile.call(this);
                return;
            }
            console.debug("profileById", result);
            _lastSeen = result.data.profileOne.latestFeatureView ? result.data.profileOne.latestFeatureView.createdAt : null;
            _profile.unseenFeatureCount = result.data.profileOne.unseenFeatureCount;
            _profile.feedback = result.data.profileOne.feedback;
            console.log("config", _config);
            console.log("company_value", company_value);
            if (
                (_config && _config.modal && _config.modal.showCta === true) ||
                (_profile.unseenFeatureCount > 0 && (!_config || !_config.modal || (_config && _config.modal && _config.modal.showCta !== false))) ||
                (company_value && company_value.modalConfig && company_value.modalConfig.alwaysShow)
            ) {
                showBanner = true;
                showBubble = true;
            }
            if (_callback) {
                _callback(_profile);
            }
            if (internalCallback && internalCallback.length > 0) {
                internalCallback.forEach(x => {
                    x.call();
                });
            }
            if (
                (
                    (company_value && company_value.modalConfig.autoOpen && _profile.unseenFeatureCount > 0) ||
                    (_config && _config.modal && _config.modal.autoOpen === true)
                ) &&
                (!_config || !_config.modal || _config.modal.autoOpen !== false)
            ) {
                loadFeatures.call(this);
            }
            // Check here again, because sometimes the mutation observer is triggered before
            // the profile is loaded, in which case we do not yet executed check checkForFeedbackModal
            // but wait for it until we loaded the profile so we can be sure that the profile did not
            // already previously provide feedback
            loadingProfile = false;
            if (location.href !== previousUrl) {
                checkForFeedbackModal(location.href);
            }
        });
    };

    const _createProfile = () => {
        createProfile.call(this, _companyId, _profile, _apiKey).then((result) => {
            console.debug("createProfile", result);
            _profile.id = result.data.profileCreateOne.record._id;
            _profile.unseenFeatureCount = result.data.profileCreateOne.record.unseenFeatureCount;
            if (
                (_config && _config.modal && _config.modal.showCta === true) ||
                (_profile.unseenFeatureCount > 0 && (!_config || !_config.modal || (_config && _config.modal && _config.modal.showCta !== false))) ||
                (company_value && company_value.modalConfig && company_value.modalConfig.alwaysShow)
            ) {
                showBanner = true;
                showBubble = true;
            }
            if (_callback) {
                _callback(_profile);
            }
            if (internalCallback && internalCallback.length > 0) {
                internalCallback.forEach(x => {
                    x.call();
                });
            }
            console.log("_config", _config);
            if (
                (
                    (company_value && company_value.modalConfig.autoOpen && _profile.unseenFeatureCount > 0) ||
                    (_config && _config.modal && _config.modal.autoOpen === true)
                ) &&
                (!_config || !_config.modal || _config.modal.autoOpen !== false)
            ) {
                loadFeatures.call(this);
            }
            createCookie("exuluProfileId", _profile.id, 30);
            // Check here again, because sometimes the mutation observer is triggered before
            // the profile is loaded, in which case we do not yet executed check checkForFeedbackModal
            // but wait for it until we loaded the profile so we can be sure that the profile did not
            // already previously provide feedback
            loadingProfile = false;
            if (location.href !== previousUrl) {
                checkForFeedbackModal(location.href);
            }
        });
    }

    /*******************/
    /****** INITS ******/
    /*******************/
    console.log("_profile", _profile);
    if (!_profile) {
        _profile = {};
    }
    console.log("_profile.external_id", _profile.external_id);
    if (!_profile.id && !_profile.external_id) {
        _profile.id = getCookie("exuluProfileId");
    }

    getCompany.call(this, _apiKey, _language).then((result) => {
        company_value = result.data.companyOne;
        if (!company_value) {
            console.error("<<<< EXULU INVALID API KEY >>>>", _apiKey);
            return;
        }
        if (!company_value.modalConfig) {
            let copy = JSON.parse(JSON.stringify(company_value)); // need to copy as it is read only
            company_value = copy;
            company_value.modalConfig = {};
        }
        _companyId = result.data.companyOne._id;
        urlList = company_value.feedbackUrls;
        if (
            urlList &&
            urlList.length > 0 &&
            // in test mode we do not load feedback modal
            (
                !_config ||
                !_config.modal ||
                !_config.modal.test
            )
        ) {
            // https://stackoverflow.com/questions/53303519/detect-an-url-change-in-a-spa
            // Alternative potential solution: https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript/41825103#41825103
            const observer = new MutationObserver(function (mutations) {
                if (location.href !== previousUrl && !loadingProfile) {
                    previousUrl = location.href;
                    if (!showFeedbackModal) {
                        checkForFeedbackModal(location.href);
                    }
                }
            });
            const config = {subtree: true, childList: true};
            observer.observe(document, config);
        }

        if (
            _config &&
            _config.modal &&
            _config.modal.test
        ) {
            loadingProfile = false;
            if (_config.modal.autoOpen) {
                loadFeatures.call(this);
            }
            if (_callback) {
                _callback(_profile);
            }
            if (internalCallback && internalCallback.length > 0) {
                internalCallback.forEach(x => {
                    x.call();
                });
            }
        } else if (_profile.external_id) {
            _profileByExternalId.call();
        } else if (_profile.id) {
            _profileById.call();
        } else {
            _createProfile.call();
        }
    });

    const transform = (value, args) => {
        if (value) {

            if (args) {
                const x = moment(value);
                return x.format(args);
            }

            const future = new Date() < new Date(value) ? true : false;
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29 && seconds > 0) {
                return "Just now";
            } // less than 30 seconds ago will show as "Just now"
            if (seconds > 604800) {
                return value.split(" ")[0];
            } // if more than 7 days ago show the date
            let intervals = null;
            if (seconds > 0) {
                intervals = {
                    "year": 31536000,
                    "month": 2592000,
                    "week": 604800,
                    "day": 86400,
                    "hour": 3600,
                    "minute": 60,
                    "second": 1
                };
            } else {
                intervals = {
                    "year": -31536000,
                    "month": -2592000,
                    "week": -604800,
                    "day": -86400,
                    "hour": -3600,
                    "minute": -60,
                    "second": -1
                };
            }
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        if (future) {
                            return "in " + counter + " " + i; // singular (1 day ago)
                        } else {
                            return counter + " " + i + " ago"; // singular (1 day ago)
                        }

                    } else {
                        if (future) {
                            return "in " + counter + " " + i + "s"; // singular (1 day ago)
                        } else {
                            return counter + " " + i + "s ago"; // plural (2 days ago)
                        }
                    }
                }
            }
        }
        return value;
    }

</script>
<main class="exulu">
    {#if company_value?.modalConfig?.trigger === 'banner' && showBanner}
        <div style="z-index: 2000; --banner-background-color: {company_value?.modalConfig.bannerBackgroundColor};"
             class="exulu-banner-{ company_value?.modalConfig.bannerPosition === 'top' ? 'top' :
                               company_value?.modalConfig.bannerPosition === 'bottom' ? 'bottom' :
                               'top'}">
            <div class="exulu-mx-auto exulu-my-2 exulu-d-flex">
                <button style="--banner-button-background-color: {company_value?.modalConfig.bannerButtonColor}; --banner-text-color: {company_value?.modalConfig.bannerTextColor};"
                        on:click={() => loadFeatures()}
                        class="exulu-btn exulu-banner-btn exulu-btn-primary exulu-my-auto exulu-d-flex">
                    {#if company_value?.modalConfig.buttonText}
                        {company_value.modalConfig.buttonText}
                    {:else if _profile.unseenFeatureCount}
                        New features
                    {:else}
                        Features
                    {/if}
                    {#if _profile.unseenFeatureCount}
                        <span class="exulu-badge exulu-badge-info exulu-ml-2">{_profile.unseenFeatureCount}</span>
                    {/if}
                </button> <!-- https://newbedev.com/how-to-pass-parameters-to-on-click-in-svelte -->
                <button class="exulu-close exulu-my-auto exulu-ml-2"
                        type="button" on:click={() => showBanner = false }
                        aria-label="Close">
                    <span aria-hidden="true">x</span>
                </button>

                <button style="z-index: 2000; position: absolute; right: 20px;
                                                                     top: 17px;" class="exulu-close" type="button"
                        on:click={closeFeedbackModal} aria-label="Close"></button>
            </div>
        </div>
    {/if}
    {#if company_value?.modalConfig?.trigger === 'bubble' && showBubble}
        <button style="
        position: fixed;
        z-index: 2000;
        --bubble-button-background-color: {company_value?.modalConfig.bannerButtonColor};
        --bubble-text-color: {company_value?.modalConfig.bannerTextColor};"
                on:click={() => loadFeatures()}
                class="{
                company_value?.modalConfig.bubblePosition === 'bottom-right' ? 'exulu-bubble-bottom-right' :
                company_value?.modalConfig.bubblePosition === 'bottom-left' ? 'exulu-bubble-bottom-left' :
                company_value?.modalConfig.bubblePosition === 'top-right' ? 'exulu-bubble-top-right' :
                company_value?.modalConfig.bubblePosition === 'top-left' ? 'exulu-bubble-top-left' :
                'exulu-bubble-bottom-right'
                } exulu-bubble exulu-my-auto exulu-d-flex">
            {#if company_value?.modalConfig.buttonText}
                {company_value.modalConfig.buttonText}
            {:else if _profile.unseenFeatureCount}
                New features
            {:else}
                Features
            {/if}
            {#if _profile.unseenFeatureCount}
                <span class="exulu-badge exulu-badge-info exulu-ml-2">{_profile.unseenFeatureCount}</span>
            {/if}
        </button>
    {/if}
    {#if showFeedbackModal}
        <div id="exulu-feedback-modal-wrapper" class="exulu-modal-wrapper-with-background">
            <div class="exulu-magictime exulu-vanishIn exulu-modal exulu-rounded exulu-m-auto"
                 style="overflow: hidden !important; --modal-background-color: {company_value?.modalConfig.modalBackgroundColor};">
                <FeedbackArea company_value={company_value} modalCallback={closeFeedbackModal} profile={_profile} feedback={feedback}></FeedbackArea>
            </div>
        </div>
    {/if}
    {#if showFeaturesModal}
        <div id="exulu-modal-wrapper"
             class={_config && _config.modal && _config.modal.background ? 'exulu-modal-wrapper-with-background' : 'exulu-modal-wrapper'}>
            <div class="exulu-magictime exulu-vanishIn { !company_value?.modalConfig?.type ? 'exulu-sidebar' :
                          company_value?.modalConfig.type === 'exulu-modal' ? 'exulu-modal exulu-rounded' :
                          'exulu-sidebar'}
                          {
                          !company_value?.modalConfig?.sideBarAlign ? 'exulu-sidebar-right' :
                          !company_value?.modalConfig?.sideBarAlign === 'right' ? 'exulu-sidebar-right' :
                           'exulu-sidebar-left'
                          }
                       "
                 style="overflow: hidden !important; --modal-background-color: {company_value?.modalConfig.modalBackgroundColor}; --modal-border-color: {company_value?.modalConfig.modalBorderColor}">
                {#if !selectedFeature}
                    <div class="exulu-col">
                        <button style="z-index: 2000; position: absolute; right: 20px;
                                                                     top: 17px;" class="exulu-close" type="button"
                                on:click={closeFeaturesModal} aria-label="Close"><span
                                aria-hidden="true">x</span></button>
                    </div>
                {/if}

                <div class="features-modal-content-exulu exulu-overflow-hidden exulu-h-100"
                     style="--modal-header-color: {company_value?.modalConfig.modalHeaderColor};
                   --modal-body-color: {company_value?.modalConfig.modalBodyColor};
                   ">
                    <div style="overflow-y: scroll;" class="exulu-modal-body">
                        <div style={selectedFeature && selectedFeature.background ? selectedFeature.background : null} class="exulu-card-body exulu-px-3 exulu-pt-3 exulu-pb-2">
                            {#if !selectedFeature}
                                {#if _config?.modal?.test}
                                    <div class="exulu-my-3"></div>
                                {/if}
                                <header>
                                    <div class="exulu-row exulu-align-items-center exulu-justify-content-between">
                                        <div class="exulu-col">
                                            <h1 class="exulu-card-title">
                                                {#if company_value?.modalConfig.i18n_whatIsNew }
                                                    {company_value.modalConfig.i18n_whatIsNew}
                                                {/if}
                                                {#if !company_value?.modalConfig.i18n_whatIsNew }
                                                    What's new
                                                {/if}
                                            </h1>
                                        </div>
                                    </div>
                                </header>
                            {/if}
                            {#if selectedFeature}
                                <header>
                                    <div class="exulu-row exulu-align-items-center exulu-justify-content-between">
                                        <div class="exulu-col">
                                            <h1 class="exulu-text-primary">{selectedFeature.name}</h1>
                                        </div>
                                        <div>
                                            <button class="exulu-float-right exulu-btn" on:click={deselectFeature}>
                                                {#if company_value?.modalConfig.i18n_back }
                                                    <span>{company_value.modalConfig.i18n_back}</span>{/if}
                                                {#if !company_value?.modalConfig.i18n_back }<span>Back</span>{/if}
                                            </button>
                                        </div>
                                    </div>
                                </header>
                            {/if}
                            <!--<hr class="exulu-mt-2 exulu-mb-2">-->
                            <div class="exulu-row"> <!-- !featuredFeature -->
                                {#if !selectedFeature && !loadingUnseenFeatures && !loadingSeenFeatures && unseen_features_value?.length < 1 && seen_features_value?.length < 1}
                                    <div class="exulu-col">
                                        <div class="exulu-mb-2">
                                            <div class="exulu-card-body">
                                                <div class="exulu-d-flex exulu-flex-column exulu-justify-content-center">
                                                    <div class="exulu-text-center exulu-px-0 exulu-px-lg-5 exulu-my-3">
                                                        <h5>
                                                            {#if company_value?.modalConfig.i18n_noNewFeatures }
                                                                <span>{company_value.modalConfig.i18n_noNewFeatures}</span>{/if}
                                                            {#if !company_value?.modalConfig.i18n_noNewFeatures }
                                                                <span>No new features found 😞</span>{/if}
                                                        </h5>
                                                        <p class="exulu-m-0">
                                                            {#if company_value?.modalConfig.i18n_checkBackHereSoon }
                                                                <span>{company_value.modalConfig.i18n_checkBackHereSoon}</span>{/if}
                                                            {#if !company_value?.modalConfig.i18n_checkBackHereSoon }
                                                                <span>Check back here soon!</span>{/if}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/if}
                                {#if selectedFeature}
                                    <div class="exulu-col-12 exulu-mb-2">
                                        <div class="exulu-feature-body">
                                            {@html selectedFeature.body}
                                        </div>
                                        {#if selectedFeature.cta}
                                            <a href="{selectedFeature.cta}" class="exulu-btn exulu-btn-primary">
                                                {#if company_value?.modalConfig.i18n_checkItOut }
                                                    {company_value.modalConfig.i18n_checkItOut}
                                                {/if}
                                                {#if !company_value?.modalConfig.i18n_checkItOut }
                                                    Check it out!
                                                {/if}
                                            </a>
                                        {/if}
                                    </div>
                                {/if}
                                {#if !selectedFeature}
                                    {#if loadingUnseenFeatures}
                                        <div class="exulu-col-12 exulu-mb-3">
                                            <!--on:click={selectFeature(unseen_feature)}-->
                                            <div class="exulu-card p-0 exulu-h-100 exulu-w-100">
                                                <div class="exulu-card-body">
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 10%; height: 21px;"></div>
                                                        <div class="exulu-skeleton exulu-rounded exulu-ml-2"
                                                             style="width: 30%; height: 21px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 80%; height: 50px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 100%; height: 30px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 100%; height: 150px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 82%; height: 30px;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/if}
                                    {#if loadingSeenFeatures}
                                        <div class="exulu-col-12 exulu-mb-3">
                                            <!--on:click={selectFeature(unseen_feature)}-->
                                            <div class="exulu-card p-0 exulu-h-100 exulu-w-100">
                                                <div class="exulu-card-body">
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 10%; height: 21px;"></div>
                                                        <div class="exulu-skeleton exulu-rounded exulu-ml-2"
                                                             style="width: 30%; height: 21px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 80%; height: 50px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 100%; height: 30px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 100%; height: 150px;"></div>
                                                    </div>
                                                    <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                        <div class="exulu-skeleton exulu-rounded"
                                                             style="width: 82%; height: 30px;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/if}
                                    {#if unseen_features_value && unseen_features_value?.length > 0 && !selectedFeature}
                                        {#if featureSearchString}
                                            <div class="exulu-col-12 exulu-mb-1 exulu-mt-3">
                                                <h2 class="exulu-card-title">
                                                    {#if company_value?.modalConfig.i18n_searchResults }
                                                        <span>{company_value.modalConfig.i18n_searchResults}</span>{/if}
                                                    {#if !company_value?.modalConfig.i18n_searchResults }
                                                        <span>Search results</span>{/if}
                                                </h2>
                                            </div>
                                        {/if}
                                        {#each unseen_features_value as unseen_feature}
                                            <div class="exulu-col-12 exulu-mb-3">
                                                <!--on:click={selectFeature(unseen_feature)}-->
                                                <div style={unseen_feature.background ? unseen_feature.background : null} class="exulu-card p-0 exulu-h-100 exulu-w-100 {unseen_feature.epic ? 'exulu-border-secondary' : ''}">
                                                    <div class="exulu-card-body">
                                                        <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                                <span class="exulu-badge exulu-badge-light exulu-mr-2">
                                                                    {#if company_value?.modalConfig.i18n_feature }
                                                                        <span>{company_value.modalConfig.i18n_feature}</span>{/if}
                                                                    {#if !company_value?.modalConfig.i18n_feature }
                                                                        <span>feature</span>{/if}
                                                                </span>
                                                            {#if unseen_feature.publishedDate}
                                                                <small class="exulu-small">
                                                                    {transform(unseen_feature.publishedDate, "DD.MM.YYYY")}
                                                                </small>
                                                            {/if}
                                                        </div>
                                                        <h5 class="exulu-card-title exulu-my-0 exulu-text-left">
                                                            {unseen_feature.name}
                                                        </h5>
                                                        {#if unseen_feature.body}
                                                            <div class="exulu-feature-body">
                                                                {@html unseen_feature.body}
                                                            </div>
                                                        {/if}
                                                        {#if unseen_feature.cta}
                                                            <div class="exulu-row">
                                                                <a href="{unseen_feature.cta}"
                                                                   class="exulu-col exulu-mt-2 exulu-text-left">
                                                                    {#if company_value?.modalConfig.i18n_checkItOut}
                                                                        {company_value.modalConfig.i18n_checkItOut}
                                                                    {:else if unseen_feature.ctaText}
                                                                        {unseen_feature.ctaText}
                                                                    {:else if !company_value?.modalConfig.i18n_checkItOut}
                                                                        Check it out!
                                                                    {/if}
                                                                </a>
                                                            </div>
                                                        {/if}
                                                        {#if unseen_feature.enableFeedback}
                                                            <FeedbackArea company_value={company_value} profile={_profile} feature={unseen_feature}></FeedbackArea>
                                                        {/if}
                                                    </div>
                                                </div>
                                            </div>
                                        {/each}
                                        {#if !featureSearchString && unseen_features_has_next_page}
                                            <div class="exulu-col-12 exulu-mb-2 exulu-d-flex">
                                                <a class="exulu-m-auto exulu-text-primary exulu-pointer"
                                                   on:click={() => {pageUnseenFeatures = pageUnseenFeatures + 1; loadFeatures(null, null, null, true, false, false);}}>
                                                    {#if company_value?.modalConfig.i18n_load_more }
                                                        <span>{company_value.modalConfig.i18n_load_more}</span>{/if}
                                                    {#if !company_value?.modalConfig.i18n_load_more }
                                                        <span>Load more</span>{/if}
                                                </a>
                                            </div>
                                        {/if}
                                    {/if}
                                    {#if seen_features_value && seen_features_value.length > 0 && !featureSearchString}
                                        {#each seen_features_value as seen_feature}
                                            <div class="exulu-col-12 exulu-mb-2">
                                                <!--on:click={selectFeature(seen_feature)}-->
                                                <div style={seen_feature.background ? seen_feature.background : null} class="exulu-card p-0 exulu-lift exulu-lift-sm exulu-h-100 exulu-w-100 {seen_feature.epic ? 'exulu-border-secondary' : ''}">
                                                    <div class="exulu-card-body">
                                                        <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                                <span class="exulu-badge exulu-badge-light exulu-mr-2">
                                                                    {#if company_value?.modalConfig.i18n_feature }
                                                                        <span>{company_value.modalConfig.i18n_feature}</span>{/if}
                                                                    {#if !company_value?.modalConfig.i18n_feature }
                                                                        <span>feature</span>{/if}
                                                                </span>
                                                            {#if seen_feature.publishedDate}
                                                                <small class="exulu-small">
                                                                    {transform(seen_feature.publishedDate, "DD.MM.YYYY")}
                                                                </small>
                                                            {/if}
                                                        </div>

                                                        <h5 class="exulu-card-title exulu-my-0 exulu-text-left">
                                                            {seen_feature.name}
                                                        </h5>
                                                        {#if seen_feature.body}
                                                            <div class="exulu-feature-body">
                                                                {@html seen_feature.body}
                                                            </div>
                                                        {/if}
                                                        {#if seen_feature.cta}
                                                            <div class="exulu-row">
                                                                <a href="{seen_feature.cta}"
                                                                   class="exulu-col exulu-mt-2 exulu-text-left">
                                                                    {#if company_value?.modalConfig.i18n_checkItOut}
                                                                        {company_value.modalConfig.i18n_checkItOut}
                                                                    {:else if seen_feature.ctaText}
                                                                        {seen_feature.ctaText}
                                                                    {:else if !company_value?.modalConfig.i18n_checkItOut}
                                                                        Check it out!
                                                                    {/if}
                                                                </a>
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </div>
                                            </div>
                                        {/each}
                                        {#if seen_features_value_has_next_page}
                                            <div class="exulu-col-12 exulu-mb-2 exulu-d-flex">
                                                <a class="exulu-m-auto exulu-text-primary exulu-pointer"
                                                   on:click={() => {pageSeenFeatures = pageSeenFeatures + 1; loadFeatures(null, null, null, false, true, false);}}>
                                                    {#if company_value?.modalConfig.i18n_load_more }
                                                        <span>{company_value.modalConfig.i18n_load_more}</span>{/if}
                                                    {#if !company_value?.modalConfig.i18n_load_more }
                                                        <span>Load more</span>{/if}
                                                    {#if loadingMoreSeenFeatures}...{/if}
                                                </a>
                                            </div>
                                        {/if}
                                    {/if}
                                    {#if company_value?.modalConfig.showFixes && fixes_value && fixes_value.length > 0 && !featureSearchString}
                                        <div class="exulu-col-12 exulu-mb-1 exulu-mt-3">
                                            <h2 class="exulu-card-title">
                                                {#if company_value?.modalConfig.i18n_latestBugFixes }
                                                    <span>{company_value.modalConfig.i18n_latestBugFixes}</span>{/if}
                                                {#if !company_value?.modalConfig.i18n_latestBugFixes }
                                                    <span>Latest bug fixes</span>{/if}
                                            </h2>
                                        </div>
                                        {#each fixes_value as fix}
                                            <div class="exulu-col-12 exulu-mb-4 exulu-pointer" on:click={() => { selectFeature(fix) }}>
                                                <div style={fix.background ? fix.background : null} class="exulu-card p-0 exulu-lift exulu-lift-sm exulu-h-100 exulu-w-100 {fix.epic ? 'exulu-border-secondary' : ''}">
                                                    <div class="exulu-card-body">
                                                        <div class="exulu-row exulu-mx-0 exulu-mb-2">
                                                                <span class="exulu-badge exulu-badge-light exulu-mr-2">
                                                                    {#if company_value?.modalConfig.i18n_bugfix }
                                                                        <span>{company_value.modalConfig.i18n_bugfix}</span>{/if}
                                                                    {#if !company_value?.modalConfig.i18n_bugfix }
                                                                        <span>bugfix</span>{/if}
                                                                </span>
                                                            {#if fix.publishedDate}
                                                                <small class="exulu-small">
                                                                    {transform(fix.publishedDate, "DD.MM.YYYY")}
                                                                </small>
                                                            {/if}
                                                        </div>
                                                        <h5 class="exulu-card-title exulu-my-0 exulu-text-left">
                                                            {fix.name}
                                                        </h5>
                                                        {#if fix.body}
                                                            <div class="exulu-feature-body">
                                                                {@html fix.body}
                                                            </div>
                                                        {/if}
                                                        {#if fix.cta}
                                                            <div class="exulu-row">
                                                                <a href="{fix.cta}"
                                                                   class="exulu-col exulu-mt-2 exulu-text-left">
                                                                    {#if company_value?.modalConfig.i18n_checkItOut}
                                                                        <span>{company_value.modalConfig.i18n_checkItOut}</span>
                                                                    {:else if fix.ctaText}
                                                                        <span>{fix.ctaText}</span>
                                                                    {:else if !company_value?.modalConfig.i18n_checkItOut}
                                                                        <span>Check it out!</span>
                                                                    {/if}

                                                                </a>
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </div>
                                            </div>
                                        {/each}
                                        {#if fixes_value_has_next_page}
                                            <div class="exulu-col-12 exulu-mb-4 exulu-d-flex">
                                                <a class="exulu-m-auto exulu-text-primary exulu-pointer"
                                                   on:click={() => {pageFixes = pageFixes + 1; loadFeatures(null, null, null, false, false, false);}}>
                                                    {#if company_value?.modalConfig.i18n_load_more }
                                                        <span>{company_value.modalConfig.i18n_load_more}</span>{/if}
                                                    {#if !company_value?.modalConfig.i18n_load_more }
                                                        <span>Load more</span>{/if}
                                                    {#if loadingMoreFixes}...{/if}
                                                </a>
                                            </div>
                                        {/if}
                                    {/if}
                                    {#if company_value?.modalConfig.showRoadmap && roadmap_values && roadmap_values.length > 0 && !featureSearchString}
                                        <div class="exulu-col-12 exulu-mb-1 exulu-mt-3">
                                            <h2 class="exulu-card-title">
                                                {#if company_value?.modalConfig.i18n_roadmap }
                                                    <span>{company_value.modalConfig.i18n_roadmap}</span>{/if}
                                                {#if !company_value?.modalConfig.i18n_roadmap }
                                                    Roadmap
                                                {/if}
                                            </h2>
                                        </div>
                                        {#each roadmap_values as roadmap_item}
                                            <div class="exulu-col-12 exulu-mb-4">
                                                <!--on:click={selectFeature(roadmap_item)}-->
                                                <div style={roadmap_item.background ? roadmap_item.background : null} class="exulu-card p-0 exulu-lift exulu-lift-sm exulu-h-100 exulu-w-100 {roadmap_item.epic ? 'exulu-border-secondary' : ''}">
                                                    <div class="exulu-card-body">
                                                        <div class="exulu-row exulu-mx-0">
                                                            {#if roadmap_item.bug}
                                                                <span class="exulu-badge exulu-badge-light">
                                                                    {#if company_value?.modalConfig.i18n_bugfix }
                                                                        <span>{company_value.modalConfig.i18n_bugfix}</span>{/if}
                                                                    {#if !company_value?.modalConfig.i18n_bugfix }
                                                                        <span>bugfix</span>{/if}
                                                                </span>
                                                            {/if}
                                                            {#if !roadmap_item.bug}
                                                                <span class="exulu-badge exulu-badge-light">
                                                                    {#if company_value?.modalConfig.i18n_feature }
                                                                        <span>{company_value.modalConfig.i18n_feature}</span>{/if}
                                                                    {#if !company_value?.modalConfig.i18n_feature }
                                                                        <span>feature</span>{/if}
                                                                </span>
                                                            {/if}
                                                        </div>
                                                        <h5 class="exulu-card-title exulu-my-0 exulu-text-left exulu-mt-2">
                                                            {roadmap_item.name}
                                                        </h5>
                                                        {#if roadmap_item.body}
                                                            <div class="exulu-feature-body">
                                                                {@html roadmap_item.body}
                                                            </div>
                                                        {/if}
                                                        {#if roadmap_item.cta}
                                                            <div class="exulu-row">
                                                                <a href="{roadmap_item.cta}"
                                                                   class="exulu-col exulu-mt-2 exulu-text-left">
                                                                    {#if company_value?.modalConfig.i18n_checkItOut}
                                                                        {company_value.modalConfig.i18n_checkItOut}
                                                                    {:else if roadmap_item.ctaText}
                                                                        {roadmap_item.ctaText}
                                                                    {:else if !company_value?.modalConfig.i18n_checkItOut}
                                                                        Check it out!
                                                                    {/if}

                                                                </a>
                                                            </div>
                                                        {/if}
                                                    </div>
                                                </div>
                                            </div>
                                        {/each}
                                        {#if roadmap_values_has_next_page}
                                            <div class="exulu-col-12 exulu-mb-2 exulu-d-flex">
                                                <a class="exulu-m-auto exulu-text-primary exulu-pointer"
                                                   on:click={() => {pageRoadmap = pageRoadmap + 1; loadFeatures(null, null, null, false, false, true);}}>
                                                    {#if company_value?.modalConfig.i18n_load_more }
                                                        <span>{company_value.modalConfig.i18n_load_more}</span>{/if}
                                                    {#if !company_value?.modalConfig.i18n_load_more }
                                                        <span>Load more</span>{/if}
                                                    {#if loadingMoreRoadmap}...{/if}
                                                </a>
                                            </div>
                                        {/if}
                                    {/if}
                                    <!--{#if loadingUnseenFeatures}
                                        <div class="exulu-loading-container exulu-d-flex exulu-w-100">
                                            <div class="exulu-loader exulu-loader&#45;&#45;style3 exulu-m-auto" title="2">
                                                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                                                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                     width="40px" height="40px" viewBox="0 0 50 50"
                                                     style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                                    <path fill="{company_value?.modalConfig.modalHeaderColor}"
                                                          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                                        <animateTransform
                                                                attributeType="xml"
                                                                attributeName="transform"
                                                                type="rotate"
                                                                from="0 25 25"
                                                                to="360 25 25"
                                                                dur="0.6s"
                                                                repeatCount="indefinite"/>
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                    {/if}-->
                                {/if}
                            </div>
                        </div>
                    </div>
                    <div class="exulu-modal-footer exulu-d-flex">
                        <div style="color: {company_value?.modalConfig.modalBodyColor} !important;"
                             class="exulu-col exulu-small">
                            Powered by <a href="https://exulu.com">Exulu</a>.
                        </div>
                        {#if !selectedFeature}
                            <div class="exulu-col exulu-input-group exulu-input-group-sm exulu-input-group-joined exulu-input-group-joined-xl exulu-border-0">
                                <input on:keyup={({ target: { value } }) => { featureSearchString = value; loadFeatures(null, null, null, true, false, false); }}
                                       class="exulu-form-control" type="search"
                                       placeholder="{company_value?.modalConfig.i18n_searchPlaceholder ? company_value.modalConfig.i18n_searchPlaceholder : 'Search'}"
                                       aria-label="Search" autofocus="">

                                <div class="exulu-input-group-append exulu-d-flex">
                                <span class="exulu-input-group-text exulu-m-auto">
                                    <svg
                                            style="color: {company_value?.modalConfig.modalBodyColor}"
                                            xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24"
                                            viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="exulu-feather exulu-feather-search"><circle
                                            cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65"
                                                                                 y2="16.65"></line></svg>
                                </span>
                                </div>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    {/if}
</main>

<style src="./main.scss" lang="scss"></style>